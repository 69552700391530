div {
    display: flex;
}
.description {
    font-family: sans-serif;
    width: 25%;
}
.retrieval-button {
    justify-content: center;
    width: 25%;
}
.resume {
    width: 50%;
    height: 100%;
}