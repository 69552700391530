html {
  height: 100%;
}
body {
  display: flex;
  width: 100%;
  min-height: 100%;
  justify-content: center;
}

div {
  display: flex;
  width: 100%;
  min-height: 100%;
}

button {
  display: flex;
  flex-direction: column;
  height: 50px;
}

embed {
  display: flex;
  flex-direction: column;
  width: 100%
}
